import { format } from "date-fns";
import React from "react";
import Button from "../../../../components/Button/Button";
import { formatTimestampUTC } from "../../../../helpers/timeConverToUtc";
import { motion } from "framer-motion";
const AnimatedCard = motion.div;
const AnimatedTable = motion.table;
const AnimatedTr = motion.tr;

export default function BillingCard(props) {
  const {
    client_id,
    created_at,
    product,
    showRejection,
    resolveRejection,
    billing,
    order_id,
    department,
  } = props;
  const formattedDate = formatTimestampUTC(created_at);
  return (
    // <>
    //   <div className="border border-neutral-300 rounded-xl w-full p-4">
    //     <div className="flex flex-row justify-between mb-4 border-b border-neutral-300 pb-4">
    //       <div className="flex-col">
    //         <div className="mb-1">
    //           <span className="font-medium text-gray-700">Order Id :</span>
    //           <span className="ml-2 text-gray-800">{order_id}</span>
    //         </div>

    //         <div>
    //           <span className="font-medium text-gray-700">Client :</span>
    //           <span className="ml-2 text-gray-800">{client_id?.name}</span>
    //         </div>
    //       </div>
    //       <div className="">
    //         <span>{formattedDate}</span>
    //       </div>
    //     </div>

    //     {showRejection && (
    //       <div className="">
    //         <div className="flex flex-row gap-4">
    //           <span>Rejection Reason : </span>

    //           <p className="font-bold">{billing?.rejection_reason}</p>
    //         </div>
    //       </div>
    //     )}

    //     {product.map((el, index) => {
    //       return (
    //         <div key={index} className="space-y-2 my-4">
    //           <div className="flex flex-row justify-between mb-2">
    //             <h1 className="text-lg font-bold">{el?.id?.name}</h1>
    //             <span className="text-lg font-bold">{el?.quantity}</span>
    //           </div>
    //         </div>
    //       );
    //     })}

    //     {showRejection && <Button label="Resolve" onClick={resolveRejection} />}
    //   </div>
    // </>

    <AnimatedCard
      className="border border-neutral-300 rounded-xl w-full p-4 bg-white shadow-lg hover:shadow-xl transition-shadow duration-300"
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex flex-row justify-between mb-4 border-b border-neutral-300 pb-4">
        <div className="flex-col">
          <div className="mb-1">
            <span className="font-medium text-gray-700">Order Id :</span>
            <span className="ml-2 text-gray-800">{order_id}</span>
          </div>

          <div>
            <span className="font-medium text-gray-700">Client :</span>
            <span className="ml-2 text-gray-800">{client_id?.name}</span>
          </div>
        </div>

        <div>
          <span className="font-medium text-gray-700">{formattedDate}</span>
        </div>
      </div>

      {showRejection && (
        <div className="mb-4 p-2 bg-yellow-50 border border-yellow-200 rounded-lg">
          <div className="flex flex-row gap-4">
            <span className="font-medium text-yellow-600">
              Rejection Reason :
            </span>
            <p className="font-bold text-yellow-800">
              {billing?.rejection_reason}
            </p>
          </div>
        </div>
      )}

      {product?.map((el, index) => (
        <div
          key={index}
          className="border border-neutral-300 p-3 rounded-lg bg-gray-50 my-4 shadow-sm"
        >
          <div className="flex flex-row justify-between mb-2">
            <h1 className="text-lg font-bold text-gray-900">{el?.id?.name}</h1>
            <span className="text-lg font-bold text-gray-900">
              {el?.quantity}
            </span>
          </div>
          <div className="mb-2">
            <span className="font-medium text-gray-700">Color :</span>
            <span className="text-gray-800">{el?.color?.name}</span>
          </div>
          <div className="mb-2">
            <span className="font-medium text-gray-700">Box :</span>
            <span className="text-gray-800">{el?.box?.name}</span>
          </div>

          <div className="mb-2">
            <p className="text-gray-700">
              <span className="font-medium">Note :</span> {el.note}
            </p>
          </div>

          <div>
            <AnimatedTable
              className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <thead>
                <tr className="bg-gray-100 border-b">
                  <th className="py-2 px-4 text-left text-gray-600">
                    Component
                  </th>

                  <th className="py-2 px-4 text-left text-gray-600">
                    Required
                  </th>
                  <th className="py-2 px-4 text-left text-gray-600">Status</th>
                </tr>
              </thead>

              <tbody>
                {el.components.map((compEl, compIndex) => (
                  <AnimatedTr
                    key={compIndex}
                    className="border-b hover:bg-gray-50"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    <td className="py-2 px-4 text-gray-800">
                      {compEl?.id?.name}
                    </td>

                    <td className="py-2 px-4 text-gray-800">
                      {compEl.quantity * el.quantity}
                    </td>
                    <td className="py-2 px-4 text-gray-800">
                      <div
                        className={`w-4 h-4 rounded-full ${
                          compEl.quantity * el.quantity <= compEl?.id?.quantity
                            ? "bg-green-500"
                            : "bg-red-500"
                        }`}
                      />
                    </td>
                  </AnimatedTr>
                ))}
              </tbody>
            </AnimatedTable>
          </div>
        </div>
      ))}

      {showRejection && <Button label="Resolve" onClick={resolveRejection} />}
    </AnimatedCard>
  );
}
