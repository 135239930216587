import React, { useEffect, useState } from "react";
import API from "../../../../API/API";
import ProductTable from "./ProductTable";

export default function ProductInventory() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [productList, setProductList] = useState([]);

  const fetchProductList = async () => {
    try {
      const response = await API.get(
        `/inventory/product/components?page=${currentPage}`
      );
      const fetchedProducts = response.products;
      const aggregated = fetchedProducts.reduce((acc, product) => {
        const key = JSON.stringify({
          product: product?.id?._id,
          box: product?.box?._id,
          color: product?.color?._id,
          cartoonOption: product?.cartoon?.cartoonOption,
          cartoonType: product?.cartoon?.cartoonType
            ? product.cartoon.cartoonType?._id
            : null,
        });

        if (!acc[key]) {
          acc[key] = {
            name: product?.id?.name,
            box: product?.box?.name,
            color: product?.color?.name,
            cartoonOption: product?.cartoon?.cartoonOption,
            cartoonType: product?.cartoon?.cartoonType
              ? product?.cartoon?.cartoonType?.name
              : null,
            quantity: 0,
          };
        }

        acc[key].quantity += product.quantity;

        return acc;
      }, {});

      const result = Object.values(aggregated);

      setProductList(result);
      setCurrentPage(currentPage);
      setTotalPages(response.total_pages);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchProductList();
  }, [currentPage]);

  return (
    <>
      <ProductTable
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
        productList={productList}
      />
    </>
  );
}
