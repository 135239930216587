import React, { useEffect, useState } from "react";
import ButtonController from "./component/ButtonController";
import API from "../../API/API.js";

import ProductTable from "./component/ProductTable";
import { CREATE_PRODUCT, EDIT_PRODUCT } from "../../constants/modelConstant";
import ProductModel from "./model/ProductModel";
import DatePicker from "react-datepicker";
import Button from "../../components/Button/Button.js";
import axios from "axios";
import Select from "react-select";

export default function Index() {
  const [orderList, setOrderList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clients, setClients] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleEndDateChange = (date) => {
    if (date) {
      // Increment the date by 1 day
      const incrementedDate = new Date(date);
      incrementedDate.setDate(incrementedDate.getDate() + 1);
      setEndDate(incrementedDate);
    } else {
      setEndDate(null);
    }
  };
  const getDisplayedEndDate = () => {
    if (!endDate) return null;
    const displayedDate = new Date(endDate);
    displayedDate.setDate(displayedDate.getDate() - 1); // Decrement by 1 day
    return displayedDate;
  };
  const fetchClientList = async () => {
    try {
      const response = await API.get(`/admin/getallclients`);
      setClients([
        { label: "All Clients", value: "" },
        ...response?.clients?.map((item) => ({
          label: item.name,
          value: item._id,
        })),
      ]);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  useEffect(() => {
    fetchClientList();
    fetchOrder();
  }, []);

  useEffect(() => {
    fetchOrder();
  }, [currentPage]);

  const fetchOrder = async () => {
    let authenticationData = localStorage.getItem("auth_token");
    if ((startDate && !endDate) || (!startDate && endDate)) {
      alert("Select Both Dates Properly!");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/orders/completedOrders`,
        {
          params: {
            startDate,
            endDate,
            page: currentPage,
            ...(selectedClient?.value && { clientId: selectedClient.value }),
          },
          headers: {
            Authorization: `Bearer ${authenticationData}`,
          },
        }
      );
      setOrderList(response?.data?.data ?? []);
      setTotalPages(
        response?.data?.total_pages !== 0 ? response?.data?.total_pages : 1
      );
      console.log("response <===>", response, response?.data);
    } catch (error) {
      console.error("Error downloading the Excel file:", error);
      // alert("Error downloading the file.");
    }
  };

  const downloadImage = async (url, name) => {
    try {
      // Fetch the image as a Blob
      const response = await fetch(url);
      const blob = await response.blob();

      // Create a temporary link to trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = name;

      // Append the link to the document and trigger the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the object URL after the download
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };
  const handleDownload = (type, imageData) => {
    const filteredData = imageData.filter((item) => item.type === type);

    filteredData.forEach((item) => {
      item.images.forEach((image) => {
        downloadImage(image.url, `${item._id}_${image._id}.jpg`);
      });
    });
  };

  return (
    <>
      <div className="flex space-x-4 mb-5">
        <div className="flex flex-col">
          <label className="text-xl font-bold text-[#3f484f]">Start Date</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Select start date"
            className="border border-gray-300 rounded-md p-2 mt-1"
            dateFormat="dd/MM/yyyy" // Set the date format here
          />
        </div>

        <div className="flex flex-col">
          <label className="text-xl font-bold text-[#3f484f]">End Date</label>
          <DatePicker
            selected={getDisplayedEndDate()} // Display previous date
            onChange={(date) => handleEndDateChange(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="Select end date"
            className="border border-gray-300 rounded-md p-2 mt-1"
            dateFormat="dd/MM/yyyy" // Set the date format here
          />
        </div>
        <div className="w-1/2 ">
          <label className="text-xl font-bold text-[#3f484f]">Client</label>
          <Select
            placeholder="Select client"
            onChange={setSelectedClient}
            isMulti={false}
            value={selectedClient}
            options={clients}
            className="react-select-container pt-[1px] mt-1"
            classNamePrefix="react-select"
          />
        </div>
        <div className="mt-[30px] w-1/3">
          <Button
            label="Filter Orders"
            onClick={fetchOrder}
            disabled={!(startDate && endDate) && !selectedClient}
            className="max-w-[250px]"
          />
        </div>
      </div>
      <ProductTable
        currentPage={currentPage}
        totalPages={totalPages}
        orderList={orderList}
        setCurrentPage={setCurrentPage}
        handleDownload={handleDownload}
      />
    </>
  );
}
