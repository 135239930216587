export function formatTimestampUTC(timestamp) {
  const date = new Date(timestamp);

  // Extracting parts of the date in UTC
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getUTCFullYear();

  // Extracting parts of the time in UTC
  let hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const isPM = hours >= 12;
  const meridian = isPM ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format and handle 0 as 12

  // Formatting the result
  return `${day}-${month}-${year} ${String(hours).padStart(
    2,
    "0"
  )}:${minutes} ${meridian}`;
}
