import React from "react";
import { CheckBox } from "../../../components/CheckBox/CheckBox.js";
import { EDIT_PRODUCT } from "../../../constants/modelConstant.js";
import UpdateQuantity from "../model/UpdateQuantity.js";
import { motion } from "framer-motion";
import { ReactComponent as DownloadIcon } from "../../../Assets/download.svg";

const ProductTable = (props) => {
  const { currentPage, orderList, totalPages, setCurrentPage, handleDownload } =
    props;
  function formatTimestampUTC(timestamp) {
    const date = new Date(timestamp);

    // Extracting parts of the date in UTC
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();

    // Extracting parts of the time in UTC
    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const isPM = hours >= 12;
    const meridian = isPM ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format and handle 0 as 12

    // Formatting the result
    return `${day}-${month}-${year} ${String(hours).padStart(
      2,
      "0"
    )}:${minutes} ${meridian}`;
  }

  return (
    <div className="">
      <motion.table
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="min-w-full bg-[#3f484f] border border-gray-300 rounded-lg shadow-lg overflow-hidden"
      >
        <thead>
          <tr className="bg-gradient-to-r from-[#3f484f] to-[#5b636b] text-white">
            <th className="py-2 px-3 w-[7%] text-left font-semibold uppercase tracking-wide text-[14px]">
              Order Id
            </th>
            <th className="py-2 px-3 w-[10%] text-left font-semibold uppercase tracking-wide text-[14px]">
              Customer Name
            </th>
            <th className="py-2 px-3 w-[10%] text-left font-semibold uppercase tracking-wide text-[14px]">
              Create Date
            </th>
            <th className="py-2 px-3 w-[7%] text-left font-semibold uppercase tracking-wide text-[14px]">
              Dispatch Date
            </th>
            <th className="py-2 px-3 w-[0.5%] text-left font-semibold uppercase tracking-wide text-[14px]">
              Bill Download
            </th>
            <th className="py-2 px-3 w-[0.5%] text-left font-semibold uppercase tracking-wide text-[14px]">
              Bilty Download
            </th>
          </tr>
        </thead>

        <motion.tbody
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0, transition: { staggerChildren: 0.1 } },
          }}
        >
          {orderList?.length > 0 ? (
            orderList?.map((el, index) => (
              <motion.tr
                key={index}
                variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
                className={`${
                  index % 2 === 0 ? "bg-[#fff9f5]" : "bg-[#fff2eb]"
                }`}
              >
                <td className="py-3 px-3 text-gray-800 font-medium text-[14px]">
                  {el?.order_id}
                </td>
                <td className="py-3 px-2 text-gray-800 font-medium text-[14px]">
                  {el?.client_id?.name}
                </td>
                <td className="py-3 px-2 text-gray-800 font-medium text-[14px]">
                  {formatTimestampUTC(el?.created_at)}
                </td>
                <td className="py-3 px-3 text-gray-800 font-medium text-[14px]">
                  {formatTimestampUTC(
                    el?.history.find(
                      (item) => item.action === "Completed order!"
                    )?.date
                  )}
                </td>
                <td className="py-3 px-3 text-gray-800 font-medium text-[14px]">
                  {el?.uploads.find((item) => item.type === "bill")?.images
                    ?.length > 0 ? (
                    <span
                      className="cursor-pointer group  p-1 rounded-lg"
                      onClick={() => handleDownload("bill", el?.uploads)}
                    >
                      <DownloadIcon
                        className="w-5 h-5 group-hover:stroke-grey"
                        stroke="#000"
                      />
                    </span>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td className="py-3 px-2 text-gray-800 font-medium text-[14px]">
                  {el?.uploads.find((item) => item.type === "bilty")?.images
                    ?.length > 0 ? (
                    <span
                      className="cursor-pointer group  p-1 rounded-lg"
                      onClick={() => handleDownload("bilty", el?.uploads)}
                    >
                      <DownloadIcon
                        className="w-5 h-5 group-hover:stroke-grey"
                        stroke="#000"
                      />
                    </span>
                  ) : (
                    "N/A"
                  )}
                </td>
              </motion.tr>
            ))
          ) : (
            <tr className="bg-[#fff2eb]">
              <td
                colSpan="10"
                className="py-6 text-center text-gray-500 text-[14px]"
              >
                No orders found
              </td>
            </tr>
          )}
        </motion.tbody>
      </motion.table>

      <div className="mt-6 flex justify-between items-center">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="py-2 px-6 bg-blue-600 text-white font-semibold rounded-lg shadow-md transition-colors duration-300 hover:bg-blue-700 disabled:bg-gray-300 text-[12px]"
        >
          Previous
        </motion.button>
        <span className="text-gray-700 font-semibold text-[12px]">
          Page {currentPage} of {totalPages}
        </span>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
          className="py-2 px-6 bg-blue-600 text-white font-semibold rounded-lg shadow-md transition-colors duration-300 hover:bg-blue-700 disabled:bg-gray-300 text-[12px]"
        >
          Next
        </motion.button>
      </div>
    </div>
  );
};

export default ProductTable;
